(function () {
    angular.module('informaApp')
        .component('videoPlayer', {
            templateUrl: 'components/video-player/template.html',
            bindings: {
                source: '<',
                previewImgUrl: '<',
                onPlayerReady: '<',
                onMediaClick: '<'
            }
        });
})();
